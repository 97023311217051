.App {
    color: white;
    position: fixed;
    width: 100%;
    height: 100%;
    background: #303953;
    overflow-y: scroll;
}

.App-header {
    padding: 14px 28px;
    text-align: left;
}

.headingLogo {
    width: 200px;
}

main {
    position: relative;
    height: 100%;
}

.fmLogo {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 150px;
}

.subheading {
    font-size: 20px;
}

.statusSegment {
    float: left;
    width: 30%;
    text-align: center;
}

.statusSegment p {
    font-size: 16px;
}
.statusSegment button {
    width: 90%;
    height: 80px;
    font-size: 26px;
    border-radius: 10px;
    border: 0;
    color: #499495;
}

.statusSegment .innerSegment {
    font-size: 18px;
    padding: 20px;
    background: #58668b;
    margin: 0 28px;
    border-radius: 6px;
}

.statusSegment .loginStatus {
    font-size: 12px;
    text-align: left;
    display: block;
    color: #d4d4d4;
}

.mainSegment {
    float: right;
    width: 70%;
    text-align: center;
    font-weight: 200;
}

.innerSegment {
    padding: 14px;
}

.mainSegment .innerSegment {
    padding: 4% 14px 14px;
}

.mainSegment .locationHeading,
.mainSegment .time,
.mainSegment .weather {
    width: 33%;
    display: inline-block;
    font-size: 20px;
    vertical-align: middle;
}

.mainSegment .locationHeading h3 {
    font-size: 20px;
    font-weight: 200;
}

.mainSegment .time .timeIndicator,
.mainSegment .time .dateIndicator,
.mainSegment .weather span {
    display: block;
    font-size: 36px;
}

.mainSegment .time .timeIndicator {
    font-size: 80px;
}

.mainSegment .cta {
    font-size: 20px;
    padding: 14px;
    border: 0;
    border-radius: 10px;
    background: #449495;
    color: white;
}

.eventsSegment {
    position: absolute;
    bottom: 64px;
    padding: 20px;
    background-color: #59668b;
    margin: 28px;
    border-radius: 6px;
}

.eventsSegment h3 {
    margin: 4px 0 8px 14px;
    text-align: left;
}

.eventsSegment table {
    width: 100%;
    table-layout: fixed;
    border-spacing: 10px;
}

.eventsSegment tr {
}

.eventsSegment td {
    height: 220px;
    padding: 18px;
    text-align: center;
    border-radius: 10px;
    font-size: 14px;
    position: relative;
    color: white;
}
/*
.eventsSegment td:nth-child(1) {
    opacity: 0.5;
}
.eventsSegment td:nth-child(2) {
    opacity: 0.7;
}
.eventsSegment td:nth-child(3) {
    opacity: 0.8;
} */

.eventsSegment td.statusOK {
    background: rgb(9, 97, 221);
    background: linear-gradient(
        180deg,
        rgba(9, 97, 221, 1) 0%,
        rgba(68, 148, 149, 1) 100%
    );
}

.eventsSegment td.statusMISSING {
    background: rgb(233, 188, 43);
    background: linear-gradient(
        183deg,
        rgba(233, 188, 43, 1) 0%,
        rgba(255, 99, 58, 1) 100%
    );
}

.eventsSegment td.statusMISSING:after {
    content: ' ';
    background: url('/public/warn.svg');
    background-size: contain;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50px;
    background-position: center;
    background-repeat: no-repeat;
    left: calc(50% - 20px);
}

.eventsSegment td.statusPENDING {
    background: #9aa4c3;
    color: #414d6f;
}

.eventsSegment span {
    display: block;
}

.eventsSegment .bottom {
    position: absolute;
    bottom: 16px;
    width: 100%;
    left: 0;
}

.eventsSegment span.statusIndicator {
    font-size: 22px;
    font-weight: bold;
}
